<template>
    <!-- SUBROGADO -->
    <b-card v-if="isSurrogate()" :header="getHeaders()" class="text-center">
        <b-card-text>
            <b-row v-for="bundle in bundles" :key="bundle.id">
                <b-col v-if="bundle.country === country">
                    <b-form-radio v-model="selectedBundleId" :value="bundle.id">
                        {{ bundle.bundleName }}
                    </b-form-radio>
                </b-col>

                <b-col v-if="bundle.country === country">
                    <b-button
                        variant="link"
                        size="lg"
                        class="p-0"
                        @click="openBundleModal(bundle.id)"
                    >
                        <b-icon icon="eye-fill" font-scale="1"></b-icon>
                    </b-button>
                </b-col>
            </b-row>
        </b-card-text>
        <template #footer>
            <b-row>
                <b-col class="text-left pa-0">
                    <b-button
                        class="btn-error-ingenes"
                        size="sm"
                        @click="selectedBundleId = null"
                        :disabled="!selectedBundleId"
                    >
                        Reset
                    </b-button>
                </b-col>
                <b-col class="text-right pa-0">
                    <b-button
                        v-if="!creatingBundle"
                        class="btn-ingenes"
                        @click="createBundle()"
                        :disabled="!selectedBundleId"
                    >
                        Create
                    </b-button>
                    <b-button v-else class="btn-ingenes" disabled>
                        <b-spinner small class="mr-2"></b-spinner>
                        Creating Bundle
                    </b-button>
                </b-col>
            </b-row>
        </template>

        <b-modal
            v-model="bundleModal"
            button-size="md"
            cancel-variant="danger"
            ok-title="Save"
            ok-variant="blue-dark"
            size="lg"
            :title="selectedBundle[0] ? `Bundle ${selectedBundle[0].bundleName}` : 'Bundle'"
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            scrollable
        >
            <CreateBusinessBundle :selectedBundle="selectedBundle" />
        </b-modal>
    </b-card>
    <!-- END SUBROGADO -->
    <b-card v-else-if="typeCard === 'Multiple'" :header="getHeaders()" class="text-center">
        <div v-if="isLoading || isLoadingProducts" class="text-center">
            <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>
        <b-card-text v-else>
            <div class="search-category">
                <div class="groupSearch mb-3">
                    <b-input-group size="sm" class="inputSearch">
                        <b-form-input
                            id="search-category"
                            v-model="filterOptions"
                            type="search"
                            :placeholder="this.$i18n.t('welcome.search')"
                            class="custom-input"
                        ></b-form-input>
                    </b-input-group>
                </div>
                <hr />
                <div class="title-items">
                    <span>{{ $t('proposal.Article') }}</span>
                    <span>
                        {{ $t('proposal.Price') }} ({{ country === 'MX' ? 'MXN ' : 'USD ' }})
                    </span>
                </div>
            </div>
            <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                    id="checkbox-group-1"
                    v-model="categorySelected"
                    :aria-describedby="ariaDescribedby"
                    name="category"
                    stacked
                >
                    <b-form-checkbox
                        v-for="(item, index) in categories"
                        :value="item.value"
                        :key="nameCategory + '-' + index"
                    >
                        <template v-if="item.top">
                            <b class="b-ingen">
                                <span
                                    v-if="
                                        proposalingen.msg != null || proposalingen.msg != undefined
                                    "
                                    class="no-ingen"
                                >
                                    <label :class="{ 'ingenes-class': hasIngenesClass(item) }">
                                        {{ item.text }}
                                        <b>
                                            <span class="text-secondary">
                                                {{ formatPrice(item.value.PrecioLista) }}
                                            </span>
                                        </b>
                                        <hr v-if="amountTop - 1 === index" />
                                    </label>
                                </span>
                                <span v-else class="no-ingen">
                                    {{ item.text }}
                                    <b>
                                        <span class="text-secondary">
                                            {{ formatPrice(item.value.PrecioLista) }}
                                        </span>
                                    </b>
                                </span>
                            </b>
                        </template>
                        <template v-else>
                            <span
                                v-if="proposalingen.msg != null || proposalingen.msg != undefined"
                                class="no-ingen"
                            >
                                <label :class="{ 'ingenes-class': hasIngenesClass(item) }">
                                    {{ item.text }}
                                    <b>
                                        <span class="text-secondary">
                                            {{ formatPrice(item.value.PrecioLista) }}
                                        </span>
                                    </b>
                                </label>
                            </span>
                            <span v-else class="no-ingen">
                                {{ item.text }}
                                <b>
                                    <span class="text-secondary">
                                        {{ formatPrice(item.value.PrecioLista) }}
                                    </span>
                                </b>
                            </span>
                        </template>
                    </b-form-checkbox>
                </b-form-checkbox-group>
            </b-form-group>
            <p v-if="!categories.length" class="my-2">No result for {{ nameCategory }}</p>
        </b-card-text>
    </b-card>
    <b-card v-else :header="getHeaders()" class="text-center" @change="getRadioValues">
        <div class="text-center" v-if="isLoading || isLoadingProducts">
            <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>
        <b-card-text v-else>
            <div class="search-product">
                <div class="groupSearch mb-3">
                    <b-input-group size="sm" class="inputSearch">
                        <b-form-input
                            :id="`search-${nameCategory}`"
                            v-model="filterOptions"
                            type="search"
                            :placeholder="this.$i18n.t('welcome.search')"
                            class="custom-input"
                        ></b-form-input>
                    </b-input-group>
                </div>

                <hr />
                <div class="title-items">
                    <span>Articulo</span>
                    <span>Precio ({{ country === 'MX' ? 'MXN ' : 'USD ' }})</span>
                </div>
            </div>
            <b-form-radio-group
                v-model="categorySelected"
                v-slot="{ ariaDescribedby }"
                v-for="(item, index) in categories"
                :key="index"
            >
                <b-form-radio
                    :aria-describedby="ariaDescribedby"
                    :name="item.category"
                    :value="item"
                >
                    <template v-if="item.top">
                        <b class="b-ingen">
                            <span
                                v-if="proposalingen.msg != null || proposalingen.msg != undefined"
                                class="no-ingen"
                            >
                                <label :class="{ 'ingenes-class': hasIngenesClass(item) }">
                                    {{ item.text }}
                                    <b>
                                        <span class="text-secondary">
                                            {{ formatPrice(item.value.PrecioLista) }}
                                        </span>
                                    </b>
                                    <hr v-if="amountTop - 1 === index" />
                                </label>
                            </span>

                            <span v-else class="no-ingen">
                                {{ item.text }}
                                <b>
                                    <span class="text-secondary">
                                        {{ formatPrice(item.value.PrecioLista) }}
                                    </span>
                                </b>
                            </span>
                        </b>
                    </template>
                    <template v-else>
                        <span
                            v-if="proposalingen.msg != null || proposalingen.msg != undefined"
                            class="no-ingen"
                        >
                            <label :class="{ 'ingenes-class': hasIngenesClass(item) }">
                                {{ item.text }}
                                <b>
                                    <span class="text-secondary">
                                        {{ formatPrice(item.value.PrecioLista) }}
                                    </span>
                                </b>
                            </label>
                        </span>
                        <span v-else class="no-ingen">
                            {{ item.text }}
                            <b>
                                <span class="text-secondary">
                                    {{ formatPrice(item.value.PrecioLista) }}
                                </span>
                            </b>
                        </span>
                    </template>
                </b-form-radio>
            </b-form-radio-group>
            <p v-if="!categories.length" class="my-2">No result for {{ nameCategory }}</p>
            <b-button size="sm" variant="outline-danger" @click="resetCheck">Reset</b-button>
        </b-card-text>
    </b-card>
</template>

<script>
import { imGetRequest, imPostRequest } from '../../api/imRequests'
import { AlertForm, ICON } from '../../helpers/Alert'
import { NumberFormat } from '../../helpers/NumberFormat'
import CreateBusinessBundle from './bundle/CreateBusinessBundle.vue'

export default {
    name: 'CardCategory',
    props: ['isLoadingProducts', 'categoryConfig', 'nameCategory', 'proposalingen'],
    components: {
        CreateBusinessBundle
    },
    data() {
        return {
            bundles: [],
            categorySelected: [],
            categories: [],
            allcategories: [],
            country: '',
            listArticles: [],
            filterOptions: '',
            isLoading: false,
            amountTop: null,
            selectedBundleId: null,
            selectedBundle: [],
            bundleModal: false,
            loadingCreation: false,
            typeCard: 'Multiple',
            creatingBundle: false,
            bundle: {
                idBundle: null,
                idPhase: null,
                idSedeDestino: JSON.parse(sessionStorage.getItem('sedeDestino'))?.value || null,
                idSedeOrigen: JSON.parse(sessionStorage.getItem('sedeOrigen'))?.value,
                nhc: JSON.parse(sessionStorage.getItem('nhc')),
                nombreSedeDestino: JSON.parse(sessionStorage.getItem('sedeDestino'))?.name,
                nombreSedeOrigen: JSON.parse(sessionStorage.getItem('sedeOrigen'))?.name,
                owner_id: JSON.parse(sessionStorage.getItem('ownerId')),
                user_comision: null,
                usuario_creador: this.$store.state.username || localStorage.getItem('userName'),
                vid: JSON.parse(sessionStorage.getItem('vid'))
            }
        }
    },

    mounted() {
        this.country = sessionStorage.getItem('country')
        this.listArticles = JSON.parse(sessionStorage.getItem('arregloArticulos')) || []
        this.updatecategories()
        this.propuestaIngenesCardCategory()

        if (this.isSurrogate()) {
            this.getBundles()
        }
    },
    watch: {
        categorySelected() {
            let arrayArticles = JSON.parse(sessionStorage.getItem('arregloArticulos')) || []

            arrayArticles = arrayArticles.filter(
                el => !this.nameCategory.includes(el.name) /* PROPIEDAD DE CATEGORIA DINAMICA */
            )
            if (this.categorySelected.length) {
                if (this.typeCard === 'Multiple') {
                    this.categorySelected.forEach(el => {
                        arrayArticles.push({
                            name: el.Categoria,
                            value: el.Articulo,
                            amount: '1',
                            description: el.CodigoAlterno,
                            price: el.PrecioLista.toString(),
                            currencyFormat: el.monedaPrecio,
                            data: el
                        })
                    })
                    sessionStorage.setItem('arregloArticulos', JSON.stringify(arrayArticles))
                }
            } else if (this.typeCard === 'Multiple') {
                sessionStorage.setItem('arregloArticulos', JSON.stringify(arrayArticles))
            }
        },

        async dataProducts() {
            await this.updatecategories()
        },
        async topProducts() {
            await this.updatecategories()
        },
        async categoryConfig() {
            setTimeout(() => {
                this.updatecategories()
            }, 2000)
        },
        filterOptions() {
            this.categories = this.allcategories.filter(el =>
                el.text.toLowerCase().includes(this.filterOptions.toLowerCase())
            )
            this.amountTop = this.categories.filter(el => el.top === true).length
        }
    },
    methods: {
        async createBundle() {
            this.creatingBundle = true
            this.bundle.idBundle = this.selectedBundleId
            this.bundle.idSedeDestino = JSON.parse(sessionStorage.getItem('sedeDestino'))?.value
            this.bundle.idSedeOrigen = JSON.parse(sessionStorage.getItem('sedeOrigen'))?.value
            this.bundle.nombreSedeDestino = JSON.parse(sessionStorage.getItem('sedeDestino'))?.name
            this.bundle.nombreSedeOrigen = JSON.parse(sessionStorage.getItem('sedeOrigen'))?.name

            const { status, data } = await imPostRequest('bundles/bundles', this.bundle)

            if (status === 201) {
                this.creatingBundle = false
                AlertForm('Creation', this.$t('admin.bundles.hsCreationMessage'), ICON.SUCCESS)
                this.$router.push({ name: 'History' })
            } else {
                AlertForm('Creation', data.msg, ICON.ERROR)
            }
            this.creatingBundle = false
        },
        async getBundles() {
            const { status, data } = await imGetRequest('bundles/bundles')
            if (status === 201) {
                this.bundles = data
            }
        },
        isSurrogate() {
            return this.nameCategory === 'SURROGATE' || this.nameCategory === 'SUBROGADO'
        },
        getHeaders() {
            return (
                (this.categoryConfig[0] && this.categoryConfig[0].label_category) ||
                this.$i18n.t('proposal.category')
            )
        },
        hasIngenesClass(item) {
            return this.proposalingen.msg.find(
                x => x.cfgd_articulo == item.text && x.cfgd_Categoria == item.value.Categoria
            )
        },
        openBundleModal(bundleId) {
            this.selectedBundle[0] = this.bundles.find(bundle => bundle.id === bundleId)
            this.bundleModal = true
        },
        async updatecategories() {
            try {
                let dataCategories = []
                this.isLoading = true
                if (this.categoryConfig.length) {
                    dataCategories = this.categoryConfig
                    this.typeCard = this.categoryConfig[0].tipocard
                }

                let cantidad = 0
                this.categories = dataCategories.map(el => {
                    if (el.isTop) cantidad++
                    return {
                        text: el.CodigoAlterno,
                        value: el,
                        top: el.isTop ? true : false
                    }
                })
                this.amountTop = cantidad
                this.allcategories = this.categories
                let filtercategory = this.listArticles.filter(el =>
                    this.nameCategory.includes(el.name)
                )

                filtercategory = filtercategory.map(el => {
                    return el.description
                })

                filtercategory = this.categories.filter(el => filtercategory.includes(el.text))

                this.categorySelected = filtercategory.map(el => el.value)
                if (this.typeCard === 'Unico' && this.categorySelected.length) {
                    this.categorySelected = {
                        text: this.categorySelected[0].CodigoAlterno,
                        top: this.categorySelected[0].isTop || false,
                        value: this.categorySelected[0]
                    }
                }
            } catch (err) {
                AlertForm(`Error Code: ${err.status || ''}`, err.statusText, ICON.ERROR)
            } finally {
                this.isLoading = false
            }
        },
        getRadioValues() {
            const arrayArticles = JSON.parse(sessionStorage.getItem('arregloArticulos'))
            const filterCategory = arrayArticles.filter(el => !this.nameCategory.includes(el.name))
            let dataSelected = {
                amount: '1',
                currencyFormat: this.categorySelected.value.monedaPrecio,
                description: this.categorySelected.text,
                name: this.categorySelected.value.Categoria,
                price: this.categorySelected.value.PrecioLista,
                top: this.categorySelected.top,
                value: this.categorySelected.value.Articulo,
                data: this.categorySelected.value
            }
            filterCategory.push(dataSelected)
            sessionStorage.setItem('arregloArticulos', JSON.stringify(filterCategory))
        },
        resetCheck() {
            this.categorySelected = ''

            const arregloArticulos = JSON.parse(sessionStorage.getItem('arregloArticulos'))
            sessionStorage.setItem(
                'arregloArticulos',
                JSON.stringify(arregloArticulos.filter(el => !this.nameCategory.includes(el.name)))
            )
        },
        formatPrice(price) {
            return NumberFormat(price)
        },
        propuestaIngenesCardCategory() {
            if (this.proposalingen.msg != null || this.proposalingen.msg != undefined) {
                if (this.proposalingen.msg.length) {
                    this.categories.forEach(item => {
                        let categoryIngen = this.proposalingen.msg.find(
                            x =>
                                x.cfgd_articulo == item.text &&
                                x.cfgd_Categoria == item.value.Categoria
                        )
                        if (categoryIngen != null) {
                            let exist = this.categorySelected.find(
                                x =>
                                    x.CodigoAlterno == categoryIngen.cfgd_articulo &&
                                    x.Categoria == categoryIngen.cfgd_Categoria
                            )
                            if (exist == null || exist == undefined) {
                                this.categorySelected.push(item.value)
                                if (item.value.Categoria == 'ELIGENDER') {
                                    if (this.typeCard === 'Unico' && this.categorySelected.length) {
                                        this.categorySelected = {
                                            text: this.categorySelected[0].CodigoAlterno,
                                            top: this.categorySelected[0].isTop || false,
                                            value: this.categorySelected[0]
                                        }

                                        const arrayArticles = JSON.parse(
                                            sessionStorage.getItem('arregloArticulos')
                                        )
                                        const filterCategory = arrayArticles.filter(
                                            el => !this.nameCategory.includes(el.name)
                                        )
                                        let dataSelected = {
                                            amount: '1',
                                            currencyFormat: this.categorySelected.value
                                                .monedaPrecio,
                                            description: this.categorySelected.text,
                                            name: this.categorySelected.value.Categoria,
                                            price: this.categorySelected.value.PrecioLista,
                                            top: this.categorySelected.top,
                                            value: this.categorySelected.value.Articulo,
                                            data: this.categorySelected.value
                                        }
                                        filterCategory.push(dataSelected)
                                        sessionStorage.setItem(
                                            'arregloArticulos',
                                            JSON.stringify(filterCategory)
                                        )
                                    }
                                }
                            }
                        }
                    })
                }
            }
        }
    }
}
</script>

<style scoped>
@import '../../assets/css/global.css';
@import '../../assets/css/proposal.css';
.groupSearch {
    width: 50%;
}
</style>
